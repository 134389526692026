import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { Stack, Image, ImageFit } from "@fluentui/react";
import Feature from "../components/Feature";
const image = require('../assets/images/taekwondo-finishing-late.jpg');

const Features: FC = () => {
    document.title = "About us | NinjaPanel - Supercharged event registration and operations with industry leading capabilities for individual competitor sports";
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    return (
        <>
            <Stack style={{padding: isMobile ? 10 : 80}} horizontal wrap tokens={{ padding:20, childrenGap:isMobile ? 40 : 60}}>
                {/* <Stack style={{maxWidth:400}} tokens={{childrenGap:20}} wrap horizontal> */}
                <Feature
                        content="Use our simple, fast and effective landing pages to direct your athletes, coaches, VIPs, referees and volunteers to register for your event."
                        title="Landing page"
                        subtitle="Create a beautiful landing page for your event, with your branding and colors."
                    />
                    <Feature
                        content="Avoid day of disasters, with rule based event registration guaranteeing only from eligible participants will be in your events."
                        title="Event Registration"
                        subtitle="Manage your events, divisions and pricing with ease." />
                    <Feature
                        content="Cut down on large volumes of email draining your energy. Athletes may edit their details up to the registration deadline with brackets dynamically updating in response to changed details."
                        title="Athlete self-editing"
                        subtitle="Allow your athletes to edit their own registration prior to the event." />
                  
                    <Feature
                        content="Forget manually typing or copying and pasting athlete details into a spreadsheet. NinjaPanel generates credentials for you, with a simple click of a button."
                        title="Credentials"
                        subtitle="Generate credentials with ease, in advance or day of your event." />
                    
                    <Feature
                        content="Generate brackets with a simple click of a button. Choose from a variety of bracket types, including Brazillian repechage ordered, single/double elimination.  Easily customize the number of competitors per bracket and eliminate same-school 1st round match ups.  Branded bracket sheets can be printed using any device and printer combination that supports PDF."
                        title="Brackets"
                        subtitle="Generate a variety of bracket types ranging from single elimination to Brazillian repechage." />

                    <Feature
                        content="Access powerful reports to help you understand your financial position, number of registrations per event, and set you up for a successful day."
                        title="Reports"
                        subtitle="Powerful reporting that keeps you in the know." />

                    <Feature
                        content="Display event status on a large screen at your event, so athletes know where to be and when. In addition, anyone at the event can bring up the status hub on their mobile device."
                        title="Status hub"
                        subtitle="Just like an airport - know where to be and when." />

                    <Feature
                        content="Receive relevant notifications on your device via email or SMS. Staging directors call athletes by bracket or division. Participants receive a notification letting them know where to be and when."
                        title="Athlete call"
                        subtitle="Get a notification on your device when it's time to take action." />
                {/* </Stack> */}                
            </Stack>
            <Image style={{ maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }} src={image} imageFit={ImageFit.contain} />

            {/* <Stack.Item id='features'>
        <Stack horizontal tokens={{ childrenGap: 40 }} wrap>
          <Stack tokens={{ childrenGap: 20 }} horizontalAlign='start' style={{ maxWidth: 200 }} wrap>
            <Icon iconName="LogRemove" style={{ fontSize: 50 }} />
            <Text variant="large" className='hero-feature'>Reduce administrative overhead.</Text>
            <Text variant='medium'>Mobile optimized registration system that allows parents or coaches to register multiple athletes at once and to add events and update athlete information after initial registration.<br /><br />Track athlete, coach, VIP and referee registrations in one integrated system.</Text>
          </Stack>
          <img style={{ maxWidth: isMobile ? 300 : 500 }} src={reduceAdminOverhead} alt='Mobile optimised with responsive design for larger screens' />
        </Stack>
      </Stack.Item>
      <br /><br /><br /><br />
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 40 }} wrap>
          <Stack tokens={{ childrenGap: 20 }} horizontalAlign='start' style={{ maxWidth: 200 }}>
            <Icon iconName="SpeedHigh" style={{ fontSize: 50 }} />
            <Text variant="large" className='hero-feature'>Improve efficiency.</Text>
            <Text variant='medium'>Rapidly create divisions, brackets and generate credentials using our powerful admin interface.<br /><br />Thoughtfully designed tournament landing pages establish trust with customers, tailored to each tournament director's needs.</Text>
          </Stack>
          <img style={{ maxWidth: isMobile ? 300 : 500 }} src={improveEfficiency} alt='Custom athlete credentials generated by NinjaPanel.' />
        </Stack>
      </Stack.Item>
      <br /><br /><br /><br />
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 40 }} wrap>
          <Stack tokens={{ childrenGap: 20 }} horizontalAlign='start' style={{ maxWidth: 200 }}>
            <Icon iconName="Chart" style={{ fontSize: 50 }} />
            <Text variant="large" className='hero-feature'>Unlock new revenue.</Text>
            <Text variant='medium'>From pre-purchasing spectator passes and t-shirts to custom pricing models, to publicizing your event, NinjaPanel is built to empower directors to get the most out of their event.</Text>
          </Stack>
          <img style={{ maxWidth: isMobile ? 300 : 500 }} src={unlockRevenue} alt='Sell spectator passes and tickets in advance, or day of your event.' />
        </Stack>
      </Stack.Item>
      <br /><br /><br /><br />
      <Stack.Item>
        <Stack horizontal tokens={{ childrenGap: 40 }} wrap>
          <Stack tokens={{ childrenGap: 20 }} horizontalAlign='start' style={{ maxWidth: 200 }}>
            <Icon iconName="IssueTracking" style={{ fontSize: 50 }} />
            <Text variant="large" className='hero-feature'>Stay on track.</Text>
            <Text variant='medium'>Event Status enables athletes to easily see when and where their next event is. Athlete Call allows staging directors to call individuals or groups of athletes directly to staging.</Text>
          </Stack>
          <img style={{ maxWidth: isMobile ? 300 : 500 }} src={stayOnTrack} alt='The Event Status display that would be shown to participants at your event.' />
        </Stack>
      </Stack.Item>
      <br /><br /><br />
      <h3 id='waitlist'>Book Demo</h3>
      <Text style={{ paddingRight: 10 }}>Book your intro to NinjaPanel demo today to see the features that will help you finish your next tournament on schedule.<br /><br /></Text>
          
      <PrimaryButton style={{ maxWidth: 300, height: 50 }} onClick={() => navigate('/demo')}>Schedule Demo</PrimaryButton> */}
        </>
    )
}

export default Features;