import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { Stack, Text, Image, ImageFit } from "@fluentui/react";
const image = require('../assets/images/master-meggie-sword.jpg');

const About: FC = () => {
    document.title = "About us | NinjaPanel - Supercharged event registration and operations with industry leading capabilities for individual competitor sports";
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    return (
        <Stack style={{ padding: isMobile ? 10 : 80 }} tokens={{ childrenGap: 10 }}>
            <h1>About us</h1>
            <h2 style={{ fontSize: 14 }}>NinjaPanel was founded by Master Meggie Presti, who holds a 5th Dan in Taekwondo and a black belt in 4 other styles of martial arts.</h2>
            <Text variant='medium'>As a life long competitor, Master Meggie has been involved in all aspects of tournament production, from planning, directing, competing, referring, staging and coordinating. The impetus for creating NinjaPanel came from a desire to make the tournament experience better for athletes and directors from start to finish.</Text>
                
            <Text variant='medium'>Far too often tournaments are riddled with day of confusion, registrations challenges, athletes not knowing where to be or when, event delays and finishing...very late. For years, we have accepted this as "normal", however by applying appropriate technology solutions, it no longer has to be.</Text>
            <Text variant='medium'>Surrounded by many tech entrepreneurs at her business in Silicon Valley, Meggie decided there had to be a better way and got to work with the help of her husband Craig who developed the platform during the pandemic.  Started as a way to keep her students engaged in classes and competition from home, NinjaPanel has evolved to support online and in person tournaments with innovative and intuitive technology.</Text>
            <Text variant='medium'>Unlike most registration platforms out there, NinjaPanel is designed by people who care about the tournament experience, and the development of competitive martial arts.  We are here to help your event be simple, smooth, and on time.</Text>
            <Image src={image} imageFit={ImageFit.contain} />
        </Stack>
    )
}

export default About;