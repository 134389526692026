import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import {Stack, Text} from "@fluentui/react";
import DemoScheduler from "../components/DemoScheduler";

const Demo: FC = () => {
    document.title = "Book a demo | NinjaPanel - Supercharged event registration and operations with industry leading capabilities for individual competitor sports";
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    return (
        <>
            <Stack style={{padding:isMobile ? 10 : 80}} horizontal wrap={isMobile} tokens={{childrenGap:isMobile ? 40 : 0}}>
                <Stack style={{maxWidth:400}} tokens={{childrenGap:10}}>
                    <h1>Book a demo</h1>
                    <h2 style={{fontSize:14}}>Learn how our technology has empowered tournament directors to deliver <em>even more</em> successful events.</h2>
                    
                    <Text variant="medium">We're excited to show you how NinjaPanel can help you deliver a better event experience for your competitors, spectators, coaches, referees and VIPs.</Text><br />
                    <Text variant='medium'>Schedule your free demo today to learn how we can help your next event be lower stress.</Text>
                </Stack>
                <DemoScheduler />
            </Stack>
        </>
    )
}

export default Demo;