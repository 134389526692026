import { Stack, Text, PrimaryButton, Image, ImageFit } from '@fluentui/react';
import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import Testimonials from '../components/Testimonials';
import { useNavigate } from 'react-router-dom';

const heroImage = require('../assets/images/finish-on-time-hero.jpg');

const Home: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
  const isLargeScreenBreak = useMediaQuery({ query: '(max-width: 900px)' });

  const navigate = useNavigate();
  const testimonials = [
    {
      author: 'Grand Master Nguyen, AAU Region 13 Director',
      text: 'The 2023 AAU District 13 Regional Qualifier was powered by NinjaPanel and saved us many hours of administrative overhead. We finished the event <strong>several hours earlier</strong> than usual.'
    },
    {
      author: 'Maryam, Owner Fitness by Maryam',
      text: 'We ran what I believe to be the worlds first girls only online tournament named Kick Like a Girl using the <strong>online tournament capabilities</strong> of NinjaPanel and my students had a wonderful experience and we look forward to using NinjaPanel for future events.'
    },
    {
      author: 'Master Meggie, Founder at Core Taekwondo',
      text: 'We used NinjaPanel for our 10 year in-school tournament and it allowed our very small team to run an <strong>efficient and memorable</strong> event that carried our branding all the way from the moment a customer landed on our registration site, through to the credentials that the system generated on our behalf.'
    }
  ]
  
  return (    
    <Stack style={{padding: isMobile ? 10 : 80}} tokens={{ childrenGap: 10 }}>
          
      <br /><br /><br />
      <Stack horizontal={!isMobile} wrap={!isMobile && isLargeScreenBreak} tokens={{ childrenGap: 80 }}>
        <Stack.Item align='center' shrink>
          <Stack>
            <Text variant="xxLarge">Finish on time,</Text>
            <Text variant="xxLarge">every time.</Text>
            <Stack.Item>
              <Stack style={{ maxWidth: 400 }} tokens={{ childrenGap: 10 }}>
                <br />
                <h1 className='defaultText'>Supercharge event registration and operations with industry leading capabilities for individual competitor sports, including Taekwondo, Karate and more.<br /><br />
                Intuitive registration reduces invalid or mistaken registrations, through rule based matching of events to athletes - avoid day of disasters and refunds due to mistaken registration!<br /><br />
                  During your event, NinjaPanel delivers automated, personalized messaging so that athletes know where to be and when via email, SMS, mobile web and large event screens allowing tournament staff to focus on what matters.</h1>
                <PrimaryButton onClick={() => navigate('/demo')} style={{ height: 50, maxWidth: '60vw', marginBottom: 20, marginTop: 20 }}>Schedule Demo</PrimaryButton>
                <Text><strong>No upfront cost</strong> with industry standard processing fees, from as little as $5 per athlete on our starter plan.</Text>
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item shrink>
        <Image imageFit={ImageFit.contain} style={{ maxWidth: isMobile ? '100%' : isLargeScreenBreak ? 400 : 700 }} src={heroImage} alt='NinjaPanel is the best tournament management software for individual competitor sports.' />
        </Stack.Item>                 
      </Stack>
      <Stack.Item align='stretch'>
            
      </Stack.Item>
      <Stack.Item align='stretch'>
        {testimonials && <><br /><br /><br /><br /><Testimonials testimonials={testimonials} /><br /><br /><br /><br /></>}
      </Stack.Item>      
      <Text variant="mediumPlus">Demo video</Text>
      <Text>See some of the key features for tournament directors in the video below.</Text><br />
        <video
          style={{width:'90%', marginLeft:'auto', marginRight:'auto'}}
          controls
          src='https://saasnpnlmarketing001.blob.core.windows.net/www/ninjapanel-overview-video.mp4' />
    </Stack>
  );
}

export default Home;