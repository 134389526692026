import { Image, ImageFit, Stack, Text } from "@fluentui/react";
import { FC } from "react";
import { PhoneIcon, TwitterLogoIcon, MyMoviesTVIcon } from "@fluentui/react-icons-mdl2"
import { useMediaQuery } from "react-responsive";

const imgSrc = require('../assets/images/ninjapanel-logo-108.png');

const Footer: FC = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const SocialFooter = () => {
        return (
            <Stack tokens={{ childrenGap: 5 }} horizontalAlign="center">
                <Image src={imgSrc} width={108} imageFit={ImageFit.center} height={50} />
                <Stack tokens={{ childrenGap: 20 }} horizontal horizontalAlign="center">
                    <a href="tel:+14157997703" target="_blank" rel="noreferrer" style={{ color: 'inherit' }}><PhoneIcon /></a>
                    <a href="https://twitter.com/ninjapanel" target="_blank" rel="noreferrer" style={{ color: 'inherit' }}><TwitterLogoIcon /></a>
                    <a href="https://www.youtube.com/@ninjapanel" target="_blank" rel="noreferrer" style={{ color: 'inherit' }}><MyMoviesTVIcon /></a>                    
                </Stack>
                <br />
                <Text variant='medium'>&copy; {new Date().getFullYear()} NinjaPanel, Inc.</Text>
                <Text variant='medium'>All Rights Reserved.</Text>                
            </Stack>)
    };
    
    return (
        <div className="footer">
            {isMobile ? <><SocialFooter /><br /></> : null}
            <Stack horizontal tokens={{ childrenGap: 60 }} wrap>
                {isMobile ? null :
                    <Stack.Item align="center">
                        <SocialFooter />
                    </Stack.Item>}
                <Stack.Item>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Text style={{ fontWeight: 'bold' }} variant='mediumPlus'>Features</Text>
                        <a href="/features/registration/landing-page"><Text variant='mediumPlus'>Landing page</Text></a>
                        <a href="/features/registration/events"><Text variant='mediumPlus'>Event registration</Text></a>
                        <a href="/features/registration/athlete-self-edit"><Text variant='mediumPlus'>Athlete self-editing</Text></a>
                        <a href="/features/operations/credentials"><Text variant='mediumPlus'>Credentials</Text></a>
                        <a href="/features/operations/brackets"><Text variant='mediumPlus'>Brackets</Text></a>
                        <a href="/features/operations/reports"><Text variant='mediumPlus'>Reports</Text></a>                        
                        <a href="/features/operations/status-hub"><Text variant='mediumPlus'>Status hub</Text></a>
                        <a href="/features/operations/athlete-call"><Text variant='mediumPlus'>Athlete call</Text></a>                        
                    </Stack>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Text style={{ fontWeight: 'bold' }} variant='mediumPlus'>Company</Text>
                        <a href="/about"><Text variant='mediumPlus'>About</Text></a>
                        <a href="/privacy.html" target='_blank' rel='noreferrer'><Text variant='mediumPlus'>Privacy Policy</Text></a>
                        <a href="/terms.html" target='_blank' rel='noreferrer'><Text variant='mediumPlus'>Terms of Service</Text></a>
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    )
}

export default Footer;