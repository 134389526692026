import {FC, useState} from 'react';
import { useMediaQuery } from 'react-responsive';
import { Spinner } from '@fluentui/react';

const DemoScheduler : FC = () => { 
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const [showSpinner, setShowSpinner] = useState<boolean>(true);

    return (
        <div style={{ minWidth:400, width: isMobile ? '100%' : '65vw', height: 'auto', overflow: 'hidden' }}>
            {showSpinner ? <Spinner /> : null}
            <iframe
                title='Book NinjaPanel Demo'
                src='https://outlook.office365.com/owa/calendar/NinjaPanelIntro@ninjapanel.com/bookings/'   
                onLoad={()=>setShowSpinner(false)}
                style={{
                    border: 0,
                    width: '100%',
                    height: '2600px',
                    background:'#fff'
                }}>
            
            </iframe>
        </div>
    );
}

export default DemoScheduler;