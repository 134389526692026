import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { Stack } from "@fluentui/react";

interface IData {
    title: string;
    subtitle: string;
    content: string;
}

const Feature: FC<IData> = ({ title, subtitle, content }) => {
    document.title = `${title} | NinjaPanel - Supercharged event registration and operations with industry leading capabilities for individual competitor sports`;
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    return (
        <div style={{height:'100%'}}>
            <Stack horizontal wrap={isMobile} tokens={{childrenGap:isMobile ? 40 : 60}}>
                <Stack style={{maxWidth:400}} tokens={{childrenGap:10}}>
                    <h2 style={{margin:0, fontSize:18}}>{title}</h2>
                    <h3 style={{ fontSize: 14, fontWeight:'normal' }}>{subtitle}</h3>                    
                    {content}
                </Stack>
                {/* <Image style={{ maxWidth: 700 }} src={image} imageFit={ImageFit.contain} /> */}
            </Stack>
        </div>
    )
}

export default Feature;