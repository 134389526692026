
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import Menu from './components/Menu';
import Footer from './components/Footer';
import { Outlet } from "react-router-dom";

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <div className='container'>        
        <div className='main'>   
          <Menu />  
          <Outlet />
        </div>
      </div>
      <Footer />
    </AppInsightsContext.Provider>

  );
}

export default App;