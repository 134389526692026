import { FC, useEffect, useState } from "react";
import { Stack, Text } from "@fluentui/react";
import { useParams } from "react-router-dom";
import BlogPost from "../components/BlogPost";
import postsData from '../assets/data/posts.json';
import { IPost } from "../model/Post";

const Blog: FC = () => {
    const blogPostParams = useParams<{ slug: string | undefined }>();
    const [posts, setPosts] = useState<IPost[]>(postsData.posts);

    useEffect(() => {
        if (blogPostParams.slug !== undefined) {            
            setPosts(posts.filter((post) => post.slug === blogPostParams.slug));
        }
        //eslint-disable-next-line
    }, [blogPostParams]);

    return (
        <div style={{padding:20, top:0}}>
            <Stack>
                {(posts === undefined || posts.length === 0) && <Text>No posts available.</Text>}
                {(posts !== undefined && posts.length > 0) && posts.map((post, index) =>  <BlogPost summary={blogPostParams.slug === undefined} key={index} post={post} /> )}
            </Stack>
        </div>
    )    
}

export default Blog;