import { FC } from "react";
import { PrimaryButton} from "@fluentui/react";
import { useNavigate } from "react-router-dom";

const DemoCallToAction: FC = () => {
    const navigate = useNavigate();

    return (
        <div style={{ height: '100%', maxWidth:500, textAlign:'center' }}>
            <h3>Want to learn more?</h3>
            Book your free demo today to see first hand how NinjaPanel can help you deliver a better event experience for your competitors, spectators, coaches, referees and VIPs.
            <br /><br />
            <PrimaryButton onClick={() => navigate('/demo')} style={{height:50}}>Schedule free demo</PrimaryButton>
        </div>
    )
}

export default DemoCallToAction;