import { IPivotStyles, Pivot, PivotItem } from "@fluentui/react"
import { FC, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";

const Menu: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [selectedKey, setSelectedKey] = useState<string>(location.pathname === '/' ? 'menu-home' : location.pathname.replace('/', 'menu-'));
    
    useEffect(() => {
        let delimitedPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
        if (delimitedPath.length === 0) {
            delimitedPath = location.pathname;
        }
        setSelectedKey(location.pathname === '/' ? 'menu-home' : delimitedPath.replace('/', 'menu-'));
    }, [location]);

    const pivotStyles: IPivotStyles = {
        linkIsSelected: {
          
        },
        root: {
    
        },
        link: {
    
        },
        linkContent: {
    
        },
        text: {
    
        },
        count: {
    
        },
        icon: {
    
        },
        linkInMenu: {
    
        },
        overflowMenuButton: {
    
        }
    }

    const navigateTo = (item: any) => {        
        setSelectedKey(item!.props.itemKey);
        
        var itemId = item!.props.id.replace('menu-', '');

        switch (itemId) {
            case 'home':
                navigate('/');
                break;
            case 'features':
                navigate('/features');
                break;
            case 'blog':
                navigate('/blog');
                break;
            case 'demo':
                navigate('/demo');
                break;
            case 'about':
                navigate('/about');
                break;
        }
    }
    
    return (
        <div style={{width:'100%', height:50}}>
            <Pivot
                styles={pivotStyles}
                aria-label="NinjaPanel Menu"
                selectedKey={selectedKey}                               
                onLinkClick={(item: any) => navigateTo(item)}
                overflowBehavior="menu">
                <PivotItem itemKey='menu-home' id='menu-home' headerText="NinjaPanel" />
                <PivotItem itemKey='menu-blog' id='menu-blog' headerText="Blog" />
                <PivotItem itemKey='menu-features' id='menu-features' headerText="Features" />
                <PivotItem itemKey='menu-demo' id='menu-demo' headerText="Schedule demo" />                
                <PivotItem itemKey='menu-about' id='menu-about' headerText="About" />                     
            </Pivot>
            <br />
        </div>
    )
}

export default Menu;