import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { ImageFit, Stack, Text, Image } from "@fluentui/react";
import { IPost } from "../model/Post";
import DemoCallToAction from "./DemoCallToAction";

interface IData {
    post: IPost;
    summary: boolean;
}

const BlogPost: FC<IData> = ({ post, summary }) => {
    document.title = `${post.title} | NinjaPanel - Supercharged event registration and operations with industry leading capabilities for individual competitor sports`;
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    return (
        <div style={{padding: isMobile ? 10 : 80}}>
            {summary ?
                <Stack horizontal wrap={isMobile} tokens={{ childrenGap: isMobile ? 40 : 60 }}>
                    <Stack style={{ maxWidth: 400 }} tokens={{ childrenGap: 10 }}>
                        <h2 style={{ margin: 0, fontSize: 18 }}>{post.title}</h2>
                        <Text style={{ fontSize: 14, fontWeight: 'normal' }}>{post.author}</Text>
                        <span style={{ fontSize: 12, fontWeight: 'normal' }}>{post.modified}</span>
                        <span dangerouslySetInnerHTML={{ __html: summary ? post.summary : post.content }} />
                        {summary && <a href={`/blog/${post.slug}`}>Read more...</a>}
                    </Stack>
                    <Image style={{ maxWidth: summary ? 300 : 700 }} src={post.imageUri} imageFit={ImageFit.contain} />
                </Stack> :
                <Stack tokens={{ childrenGap: 10 }}>
                    <h2 style={{ margin: 0, fontSize: 18 }}>{post.title}</h2>
                    <Text style={{ fontSize: 14, fontWeight: 'normal' }}>{post.author} | {post.modified}</Text>
                    <span dangerouslySetInnerHTML={{ __html: summary ? post.summary : post.content }} />
                    <div style={{ marginLeft: 'auto', marginRight:'auto'}}>
                        <DemoCallToAction />
                    </div>
                </Stack>
            }
        </div>
    )
}

export default BlogPost;