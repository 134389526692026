import React from 'react';
import ReactDOM from 'react-dom';
import './assets/App.css';
import App from './App';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';

import Home from './views/Home';
import Demo from "./views/Demo";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import PageNotFound from './views/PageNotFound';
import About from './views/About';
import Feature from './components/Feature';
import Features from './views/Features';
import Blog from './views/Blog';
initializeIcons();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<PageNotFound />}>
      <Route index element={<Home />} />
      
      <Route path='/features' element={<Features />}>
        <Route path='registration'>
          <Route path='landing-page' element={<Feature title='Landing Page' subtitle='Registration' content='Landing Page' />} />
          <Route path='events' element={<Feature title='Events' subtitle='Registration' content='Events' />} />
          <Route path='athlete-self-edit' element={<Feature title='Athlete Self-Edit' subtitle='Registration' content='Athlete Self-Edit' />} />
        </Route>

        <Route path='operations'>
          <Route path='credentials' element={<Feature title='Credentials' subtitle='Operations' content='Credentials' />} />
          <Route path='brackets' element={<Feature title='Brackets' subtitle='Operations' content='Brackets' />} />
          <Route path='reports' element={<Feature title='Reports' subtitle='Operations' content='Reports' />} />
          <Route path='status-hub' element={<Feature title='Status Hub' subtitle='Operations' content='Status Hub' />} />
          <Route path='athlete-call' element={<Feature title='Athlete Call' subtitle='Operations' content='Athlete Call' />} />
        </Route>
      </Route>
      <Route path='/blog/:slug' element={<Blog />} />
      <Route path='/blog' element={<Blog />} />
      <Route path='/about' element={<About />} />
      <Route path="/demo" element={<Demo />} />
    </Route>
  ));
  
ReactDOM.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
