import { Stack, Text } from '@fluentui/react';
import { FC } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; 

export interface ITestimonial {
    author: string;
    text: string;    
}

interface IData {
    testimonials: ITestimonial[];
}

const Testimonials: FC<IData> = ({ testimonials }) => (
    <Stack>
        <div style={{backgroundColor:'#f4f4f4', textAlign:'center'}}>
            <h3><Text variant="large">Trusted by experienced tournament directors to handle their events.</Text></h3>
        </div>
        <div
            style={{
                display: 'block',
                borderColor: '#f3f3f3',
                borderWidth: '2px',
                borderStyle: 'solid',
            }}>
            <Swiper
                style={{
                    maxWidth: '90vw',
                    width:'auto',
                    height: 'auto',
                    paddingBottom: 10,
                    paddingRight:10}}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 11000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}>
                {testimonials.map((testimonial, idx) =>
                    <SwiperSlide key={idx}>
                        <blockquote dangerouslySetInnerHTML={{ __html: testimonial.text }} />
                        <Text style={{marginLeft:'40px'}} variant='smallPlus'> <em>- {testimonial.author}</em></Text>
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    </Stack>
)

export default Testimonials;